<template>
  <div class="wbobyy">
    <div id="wrapper">
      <div class="input-data">
        <input type="text" v-model="Noitem" onkeyup="value=value.replace(/[^a-zA-Z0-9]/g,'')"
          @blur="Noitem = Noitem.replace(/[^a-zA-Z0-9]/g, '')"
          oninput="if (value.trim() === '') { value = null; }" @keyup.enter="ERPShipment()" required>
        <div class="underline">
          <i class="el-icon-circle-close" v-show="Noitem.length > 0"
            style="position: absolute;top: -30px;right: 60px;color:#9fa1a7;" @click="Noitem = ''"></i>
          <el-button type="primary" style="position: absolute;top: -35px;right: 1px;" size="mini" icon="el-icon-search"
            @click="ERPShipment()"></el-button>
        </div>
        <label>{{ $t('All.请输入出货单号') }}</label>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      dialogVisible: false,
      Noitem: '',
      Numbers: '',
      OQCdata: {},
      OQCNumbers: ''
    }
  },
  created () {
  },
  props: ['Addtab', 'RemoveTab', 'Renewtargename', 'Rendw'],
  mounted () {
  },
  methods: {
    ...mapMutations(['OQCdatafun']),
    async ERPShipment () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const OQCdataget = {
          UserData: this.$store.state.Login,
          Wmdoco: this.Noitem.trim()
        }
        const { data: res } = await this.$http.post('/api/OQC/ERPShipment', OQCdataget)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.response)
        }
        loadingInstance.close()
        this.OQCdata = res.response
        this.dialogVisible = true
        this.OQCdatafun(this.OQCdata)
        this.Addtab(this.$t('All.新OQC检验单') + '-' + res.response.Numbers, 'NewOQCUser')
        this.RemoveTab(this.$t('All.新OQC检验单'))
        /* this.$nextTick(() => { // 异步关闭
          this.TabComponentFun('NewOQCAdmin')
        }) */
      } catch (error) {
        loadingInstance.close()
        alert(error)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.wbobyy {
  position: absolute/fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  height: 0px;
  width: 300px;
}
</style>
